/** Font Sizes */
$fontSizeXLarge: 2.6rem;
$fontSizeLarge: 2.5rem;
$fontSizeMedium: 2.2rem;
$fontSizeXMedium: 1.85rem;
$fontSizeSmall: 1.55rem;
$fontSizeXSmall: 1.25rem;

/** Font Weights */
$fontWeightLight: 300;
$fontWeightNormal: 400;
$fontWeightMedium: 600;
$fontWeightXMedium: 500;
$fontWeightBold: 700;

/** Font Family */
$fontFamilyBeiruti: "Beiruti", sans-serif;
