@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

.cdis--select-common {
  background-color: $colorInputs !important;
  border: none;
  color: $primaryColor;
  outline: none;
  padding: 1vh;
  border-radius: $borderRadius-19;
  font-size: $fontSizeXMedium;
  font-weight: $fontWeightNormal;
  font-family: $fontFamilyBeiruti;
  direction: rtl !important;
  min-height: 7.2vh !important;
}


/*width:986px*/
.cdis--select-large {
  width: 61.6rem;
}
/*width:268px*/
.cdis--select-medium {
  width: 100%;
}

/*width:192px*/
.cdis--select-small {
  //width: 12rem;
  width: 100%;
}
.cdis--select-dashboard{
  background-color: $secondaryColor !important;
  color: $whiteColor;
  font-size: 1.3rem;
padding: 1% 2%;
border-radius: $borderRadius-19;
width: 100%;
cursor: pointer;
option {
  background-color: #f3efef !important;
  color: #024059 !important;
}

}
