@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

.cdis--search-input {
  .ant-input-affix-wrapper {
    border: $borderWidth-1 solid $secondaryColor !important;
    box-shadow: none !important;
    text-align: right;
    border-radius: $borderRadius-11 !important;
    padding: 0.5rem 1.5rem;
  }

  .ant-input::placeholder {
    color: $primaryColor !important;
    font-family: $fontFamilyBeiruti !important;
    font-size: $fontSizeXSmall !important;
    opacity: 0.6 !important;
  }
  .ant-input-affix-wrapper .ant-input-suffix .anticon {
    color: $primaryColor !important;
    font-size: $fontSizeXSmall !important;
  }
  .ant-input-affix-wrapper .ant-input-suffix {
    margin: 0;
  }
  .ant-input-group-addon {
    display: none !important;
  }
}
