@font-face {
  font-family: "Beiruti";
  src: local("Beiruti"),
    url("./assets/fonts/Beiruti-VariableFont_wght.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.cdis--app-container {
  background-color: transparent;
  height: 100%;
  overflow: scroll;
  width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.cdis--app-container::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.cdis--app-container {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}
input[type="date"] {
      color: #024059; /* Change text color */
    }

    /* Try to target the calendar icon (may not work in all browsers) */
    input[type="date"]::-webkit-calendar-picker-indicator {
      filter: invert(20%) sepia(100%) saturate(300%) hue-rotate(180deg);
    }
     input[type="text"] {
      text-align: right;
    }
.App {
  margin-top: 10px;
  padding: 20px;
}

.table-container {
  margin: 20px auto;
  width: 80%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.table-container th,
.table-container td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;
}

.table-container th {
  background-color: #f2f2f2;
}

.table-container tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
