@import "../variables/color.scss";
@import "../variables/border.scss";
@import "../variables/font.scss";

.cdis--cadre-dashboard-container {
  width: 100%;
  height: fit-content;
  padding: 10% 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: $borderWidth-1 solid $secondaryColorDark;
  border-radius: $borderRadius-21;
  direction: rtl;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cdis--cadre-dashboard-text-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cdis--cadre-dashboard-icon {
  margin-bottom: 8px;
}

.cdis--cadre-dashboard-text {
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeXMedium;
  font-weight: $fontWeightMedium;
  color: $primaryColor;
  margin-bottom: 8px;
}

.cdis--cadre-dashboard-number {
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeLarge;
  font-weight: $fontWeightBold;
  color: $primaryColor;
}

.cdis--cadre-dashboard-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.cdis--cadre-dashboard-title {
  width: 100%;
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeXMedium;
  font-weight: $fontWeightNormal;
  color: $colorText;
  text-align: right;
}
