@import "../variables/color.scss";
/* HTML: <div class="loader"></div> */
.loader {
  width: 20px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: $colorInputs;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
.loading-color {
  background: $secondaryColor;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
/* HTML: <div class="loader"></div> */
.section-loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  margin-top: 8rem;
  border-radius: 50%;
  background: linear-gradient(
        0deg,
        rgba(2, 64, 89, 0.5) 30%,
        #0000 0 70%,
        rgba(2, 64, 89, 1) 0
      )
      50%/8% 100%,
    linear-gradient(
        90deg,
        rgba(2, 64, 89, 0.25) 30%,
        #0000 0 70%,
        rgba(2, 64, 89, 0.75) 0
      )
      50%/100% 8%;

  background-repeat: no-repeat;
  animation: l23 1s infinite steps(12);
}
.section-loader::before,
.section-loader::after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: inherit;
  opacity: 0.915;
  transform: rotate(30deg);
}
.section-loader::after {
  opacity: 0.83;
  transform: rotate(60deg);
}
@keyframes l23 {
  100% {
    transform: rotate(1turn);
  }
}
