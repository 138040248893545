@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";
.cdis--side-bar-container {
  background-color: $whiteColor;
  padding: 2% 0 2% 2%;
  .cursor-pointer {
    cursor: pointer;
  }
  .cdis--layout {
    background-color: $whiteColor;
    gap: 3vh;
    .cdis--sideBar {
      display: flex;
      margin-right: 316px;
      justify-content: space-between;
      align-items: stretch;
      flex-direction: row-reverse;
      .cdis-logo-title {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 0.5vw;
        .cdis--header-first-title {
          font-family: $fontFamilyBeiruti;
          font-size: 1.5rem;
          font-weight: $fontWeightNormal;
          color: $primaryColor;
          line-height: 1;
        }
        .cdis--header-second-title {
          font-family: $fontFamilyBeiruti;
          font-size: 1.8rem;
          font-weight: $fontWeightNormal;
          color: $primaryColor;
          line-height: 1;
        }
      }
    }
  }
  .cdis--sidebar-title {
    font-family: $fontFamilyBeiruti;
    font-size: 1.5rem;
    font-weight: $fontWeightNormal;
    text-align: center;
    color: $whiteColor;
  }
  .ant-layout-sider {
    background-color: $primaryColor;
    border-radius: 18px;
    margin: 1%;
    width: 17rem !important;
    max-width: 17rem !important;
    .ant-menu {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      display: flex;
      direction: rtl;
      background-color: $primaryColor;
      gap: 1vw;
      .ant-menu-item-selected {
        background-color: $whiteColor;

        .ant-menu-title-content {
          color: $primaryColor !important;
        }
        .ant-menu-item-icon {
          filter: invert(16%) sepia(96%) saturate(500%) hue-rotate(160deg)
            brightness(45%) contrast(171%) !important; // }
        }
      }

      .ant-menu-item {
        .ant-menu-item-icon {
          filter: brightness(132);
        }

        width: 12.5rem;
        border: 1px solid $whiteColor;

        .ant-menu-title-content {
          border-radius: 10px;
          font-family: $fontFamilyBeiruti;
          font-weight: $fontWeightNormal;
          font-size: 1.5rem;
          color: $whiteColor;
        }
      }
    }
  }
}
.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vw;
  .cdis--sidebar-logout {
    width: 12.5rem;
    position: absolute;
    bottom: 0;
    margin-bottom: 2vw;
  }
}

.cdis-logo-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
