/** Border Radiuses */
$borderRadius-10: 0.65rem;
$borderRadius-11: 0.7rem;
$borderRadius-18: 1.15rem;
$borderRadius-19: 1.2rem;
$borderRadius-21: 1.3rem;
$borderRadius-43: 2.7rem;
$borderRadius-57:3.5rem;
$borderRadius-50: 50%;

/** Border Widths */
$borderWidth-1: 0.06rem;
$borderWidth-2: 0.15rem;
