@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

//Common CSS
.cdis--input-common {
  background-color: $colorInputs !important;
  border: none;
  color: $primaryColor;
  outline: none;
  padding-right: 2vh;
  border-radius: $borderRadius-19;
  font-size: $fontSizeXMedium;
  font-weight: $fontWeightNormal;
  font-family: $fontFamilyBeiruti;
  direction: rtl;
  &::placeholder {
    color: $primaryColor;
    font-size: $fontSizeXMedium;
    font-weight: $fontWeightNormal;
    font-family: $fontFamilyBeiruti;
  }
      min-height: 7.2vh !important;

}
/* width:481px*/
.cdis--input-withoutIcon-huge {
  width: 30rem;
}
input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
  opacity: 0.4;
  margin-left: 5px;
}

input[type="select"]::-webkit-datetime-edit-text,
input[type="select"]::-webkit-datetime-edit-month-field,
input[type="select"]::-webkit-datetime-edit-day-field,
input[type="select"]::-webkit-datetime-edit-year-field {
  opacity: 0.4;
  margin-left: 5px;
}

/* width:433px*/
.cdis--input-withoutIcon-extraLarge {
  width: 100%;

}

/* width:268px*/
.cdis--input-withoutIcon-large {
  // width: 16.7rem;
  width: 100%;
}
/* width:253px*/
.cdis--input-withoutIcon-mediumLarge {
  //width: 15.8rem;
  width: 100%;
}
/* width:235px*/
.cdis--input-withoutIcon-medium {
  width: 100%;
}
/* width:182px*/
.cdis--input-withoutIcon-mediumSmall {
  //width: 11.4rem;
  width: 100%;
}
/* width:165px*/
.cdis--input-withoutIcon-normal {
  //width: 10rem;
  width: 100%;
}
/* width:152px*/
.cdis--input-withoutIcon-small {
  width: 100%;
}

//Container input with icon
.container-input-with-icon {
  position: relative;
  display: inline-block;
  min-width: 100%;

  img {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  /* width:481px*/
  .input-with-icon {
   min-width: 100%;
    //padding: 2.5%;
  }
}
input:-internal-autofill-selected {
  background-color: $colorInputs !important;
}
input::placeholder {
  opacity: 0.4;
}