@import "../variables/color.scss";
@import "../variables/font.scss";

//Navigation Menu Bar
.cdis--navigation-bar {
  display: flex;
  width: 100%;
  height: 6rem;
  position: fixed;
  z-index: 999;
  top: 0;
  background-color: $whiteColor;
  align-items: center;
  justify-content: space-between;
  padding: 0 4% 0 2%;

  .cdis--navbar-list-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3vw;

    .cdis--navbar-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1vw;
    }

    .cdis--navbar-btn {
      border-color: $primaryColor;
      color: $primaryColor;
      background-color: $whiteColor;
    }
    .cdis--primary-btn {
      padding: 0.5rem 2.5rem;
    }
  }
  .cdis--outlined-btn {
    border-color: $primaryColor;
    color: $primaryColor;
  }
  .cdis--image-container {
    display: flex;
    gap: 1vw;
    width: 20rem;
    img:first-child {
      width: 30%;
    }
    img:last-child {
      width: 50%;
    }
  }
}

//Navigation Menu
.cdis--navigation-menu-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  background-color: $whiteColor;
  color: $secondaryColor;
  list-style: none;
  direction: rtl;

  .cdis--list-item {
    cursor: pointer;
    font-family: $fontFamilyBeiruti;
    font-size: $fontSizeXMedium;
    font-weight: $fontWeightXMedium;
  }

  #selected-item {
    color: $primaryColor;
  }
}

// Burger button -----------------------------------------

.cdis--burger-menu-container {
  display: none;
  align-self: center;
  background-color: $whiteColor;
  border-radius: 50%;
  cursor: pointer;
  height: 59px;
  width: 59px;
  z-index: 3;
  .cdis--burger-logo {
    width: 9rem;
    img {
      width: 100;
    }
  }
}

//Burger button when opened -------------------------------
.cdis--list-burger-items {
  // background-color: red;
  width: 100%;
  gap: 4vh;
  padding-right: 16%;
  height: calc(100vh - 21vh);
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  .cdis--navbar-btn {
    border-color: $primaryColor;
    color: $primaryColor;
    background-color: $whiteColor;
  }

  .cdis--primary-btn {
    padding: 0.5rem 2rem;
  }
}

.cdis--burger-menu-container-opened {
  position: fixed;
  top: 5rem;
  right: 0;
  align-self: flex-start;
  background-color: $whiteColor;
  box-sizing: border-box;
  cursor: pointer;
  flex-direction: column;
  gap: 7vh;
  padding: 7vh 0;
  width: 14.5rem;
  z-index: 3;
  display: none;
  align-items: center;
  justify-content: flex-start;

  //styling the content
  .cdis--burger-menu-content {
    background-color: $whiteColor;
    flex-direction: column;
    width: 100%;
    display: flex;
  }
}

// Burger button -----------------------------------------
.cdis--burger-menu-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 25rem;
  background-color: $whiteColor;
  color: $secondaryColor;
  list-style: none;
  direction: rtl;

  .cdis--list-item {
    cursor: pointer;
    font-family: $fontFamilyBeiruti;
    font-size: $fontSizeXMedium;
    font-weight: $fontWeightXMedium;
  }

  #selected-item {
    color: $primaryColor;
  }
  width: 100%;
  gap: 7vh;
  flex-direction: column;
}
.cdis--burger-btn-container {
  display: none;
  align-self: center;
  background-color: $primaryColor;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  flex-direction: column;
  gap: 8.7px;
  height: 53px;
  padding: 2%;
  width: 53px;
  align-items: center;
  justify-content: center;
  .bar {
    background-color: $whiteColor;
    height: 2px;
    transition: ease-in 0.25s;
    width: 29px;
  }
  .bar-2 {
    opacity: 0;
  }

  .bar-1 {
    background-color: $whiteColor;
    height: 2px;
    margin-top: 10px;
    transform: rotate(45deg);
    transition: ease-out 0.45s;
    width: 29px;
  }

  .bar-3 {
    background-color: $whiteColor;
    height: 2px;
    margin-top: 2px;
    position: absolute;
    transform: rotate(-45deg);
    transition: ease-out 0.45s;
    width: 29px;
  }
}

//Burger button when opened
.cdis--burger-btn-container-opened {
  align-self: flex-start;
  background-color: $whiteColor;
  border: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

/*---------------------------Media Queries------------------------*/

@media (max-width: 1138px) {
  .cdis--navigation-bar {
    .cdis--navigation-menu-list {
      width: 23rem;
      gap: 1vw;
      .cdis--list-item {
        font-size: 1.1rem;
      }
    }
    .cdis--outlined-btn {
      font-size: 1.1rem !important;
    }
    .cdis--primary-btn {
      font-size: 1.1rem;
    }
  }
}

@media (max-width: 990px) {
  .cdis--navigation-bar {
    .cdis--navigation-menu-list {
      width: 18rem;
    }
  }
}

@media (max-width: 900px) {
  .cdis--burger-btn-container {
    display: flex;
  }
  .cdis--navigation-bar {
    .cdis--navbar-list-container {
      display: none;
    }
  }

  .cdis--burger-menu-container-opened {
    display: flex;
  }
}
@media (max-width: 700px) {
  .cdis--navigation-bar {
    .cdis--image-container {
      width: 15rem;
    }
  }
}
@media (max-width: 595px) {
  .cdis--navigation-bar {
    .cdis--image-container {
      width: 12rem;
    }
    .cdis--burger-btn-container {
      width: 47px;
      height: 47px;
    }
  }
}
@media (max-width: 420px) {
  .cdis--navigation-bar {
    .cdis--image-container {
      width: 10rem;
    }
  }
}
