@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

.cdis--button-text-big {
  padding: 1rem 8rem;
  background-color: $secondaryColor;
  border-radius: $borderRadius-19;
  color: $whiteColor;
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightNormal;
  border: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  direction: rtl;
  gap: 0.5rem;
  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
}
.cdis--button-disabled {
  opacity: 0.4 !important;
}

.cdis--button-primary {
  padding: 0.5rem 4.55rem;
  background-color: $primaryColor;
  border-radius: $borderRadius-10;
  color: $whiteColor;
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightNormal;
  border: none;
  cursor: pointer;
}

.cdis--button-secondary {
  padding: 0.5rem 5rem;
  background-color: $secondaryColor;
  border-radius: $borderRadius-10;
  color: $whiteColor;
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightNormal;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  direction: rtl;
}

.cdis--button-outlined {
  padding: 0.5rem 2.5rem;
  background-color: $whiteColor;
  border-radius: $borderRadius-10;
  color: $secondaryColor;
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightNormal;
  border: $borderWidth-1 solid $secondaryColor;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.cdis--button-faded {
  padding: 0.5rem 4.55rem;
  background-color: $secondaryColorDark;
  border-radius: $borderRadius-10;
  color: $whiteColor;
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightNormal;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.cdis--button-text-icon {
  width: 25rem;
  padding: 0.5rem;
  background-color: $secondaryColor;
  border-radius: $borderRadius-10;
  color: $whiteColor;
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightNormal;
  border: none;
  cursor: pointer;

  .button-text-icon-container {
    direction: rtl;
    display: flex;
    align-items: center;
  }
  img {
    margin-left: 1rem;
  }
}

.cdis--opportunities-button {
  padding: 0.5rem 4.55rem;
  color: $whiteColor;
  background-color: $primaryColor;
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightNormal;
  border: none;
  cursor: pointer;
  background-color: $primaryColor;
  border-radius: $borderRadius-10;
  width: 100%;
}
.cdis--button-opportunities-2 {
  background-color: $secondaryColor;
}
.opacity-60 {
  opacity: 0.6;
}

@media (max-width: 1138px) {
  .cdis--button-secondary {
    padding: 0.5rem 3rem;
    font-size: 1.4rem;
  }
}

