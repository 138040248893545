@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";
.cdis--details-card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2.5%;
  direction: rtl;
  .cdis--details-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 57%;
    cursor: pointer;
  }
  .cdis--box-details-container {
    display: flex;
    gap: 1vw;
  }
  //news card
  .cdis--news-details-card {
    display: flex;
    width: 60%;
    justify-content: center;
    gap: 5vw;
    h6 {
      width: 30%;
      text-align: start;
    }
    .cdis--box-details-container {
      width: 70%;
    }
  }
  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
  }

  h6,
  p,
  span,
  label {
    font-family: $fontFamilyBeiruti;
    font-weight: $fontWeightNormal;
    color: $primaryColor;
  }

  h6 {
    font-size: $fontSizeSmall;
    line-height: 1.5;
    max-width: 100%; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
  }

  p {
    font-size: $fontSizeXSmall;
    line-height: 1.75;
    max-width: 100%; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  white-space: nowrap;
  }
  span {
    font-size: $fontSizeXMedium;
  }
  label {
    font-size: $fontSizeXMedium;
    color: $secondaryColor;
  }
}
//common

.cdis--card-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  gap: 0.5vw;
  width: 21rem;
  height: 25rem;
  border-radius: 18px;
  background-color: transparent;
  opacity: 0;
  transform: translateX(100%);
  animation: slideIn 0.3s forwards;
  .cdis--card-image {
    border-radius: 18px;
    min-height: 63%;
    max-height: 63%;
    width: 100%;
    img {
      border-radius: 18px;
      height: 100%;
      //object-fit: fill;
      width: 100%;
    }
  }
  .cdis--description-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    h6,
    p,
    span,
    label {
      font-family: $fontFamilyBeiruti;
      font-weight: $fontWeightNormal;
      color: $primaryColor;
    }

    h6 {
      font-size: $fontSizeSmall;
      line-height: 1.5;
    }
    .cdis--box-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      width: 100%;
      gap: 1vw;

      p {
        font-size: $fontSizeXSmall;
        line-height: 1.75;
      }
      span {
        font-size: $fontSizeXMedium;
      }
      label {
        font-size: $fontSizeXSmall;
        color: $secondaryColor;
      }
    }
  }
}
@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/*******************News Cards*************************/
.cdis--card-with-description {
  display: flex;
  //width: 50%;
  height: 20rem;
  cursor: pointer;
  background-color: $colorInputs;
  .cdis--image-container {
    width: 50%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      // object-fit: cover;
    }
  }
  .cdis--description-container {
    display: flex;
    width: 50%;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    direction: rtl;
    gap: 1vw;
    padding: 3vw;
    flex-direction: column;
    h6 {
      font-family: $fontFamilyBeiruti;
      font-weight: $fontWeightBold;
      color: $primaryColor;
      font-size: 1.7rem;
    }
    P {
      width: 100%;
      line-height: 1.25;
      font-family: $fontFamilyBeiruti;
      font-weight: $fontWeightXMedium;
      color: $primaryColor;
      font-size: $fontSizeXSmall;
    }
  }
}

.cdis--news-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 20rem;
  background-color: $colorInputs;
  .cdis--image-container {
    width: 100%;
    height: 64%;
    img {
      width: 100%;
      height: 100%;
      
    }
  }
  .cdis--description-container {
    display: flex;
    width: 100%;
    height: 36%;
    padding: 0 2vw 0 0;
    align-items: flex-start;
    justify-content: center;
    direction: rtl;
    gap: 0.5vw;
    flex-direction: column;
    h6 {
      font-family: $fontFamilyBeiruti;
      font-weight: $fontWeightBold;
      color: $primaryColor;
      font-size: 1.7rem;
    }
    P {
      width: 100%;
      font-family: $fontFamilyBeiruti;
      font-weight: $fontWeightXMedium;
      color: $primaryColor;
      font-size: $fontSizeXSmall;
    }
  }
}

.cdis--card-with-title {
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  gap: 0.5vw;
  height: 20rem;
 
  background-color: $colorInputs;
  .cdis--card-image {
    height: 64%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }
  .cdis--title-container {
    height: 36%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    direction: rtl;
    h6 {
      font-family: $fontFamilyBeiruti;
      font-weight: $fontWeightBold;
      color: $primaryColor;
      font-size: 1.7rem;
    }
  }
}
/*******************Media Quries*************************/
@media (max-width: 1179px) {
  .cdis--news-card {
    .cdis--description-container {
      h6 {
        font-size: 1.5rem !important;
      }
      P {
        font-size: 1.1rem;
      }
    }
  }
  .cdis--card-with-description {
    .cdis--description-container {
      h6 {
        font-size: 1.5rem !important;
      }
      p {
        font-size: 1.1rem;
      }
    }
  }
}
@media (max-width: 1138px) {
  .cdis--card-container {
    .cdis--description-container {
      h6 {
        font-size: 1.3rem;
      }
      .cdis--box-container {
        p {
          font-size: 1.15rem;
        }
        span {
          font-size: 1.7rem;
        }
        label {
          font-size: 1.7rem;
        }
      }
    }
  }
}
@media (max-width: 970px) {
  .cdis--news-card {
    .cdis--description-container {
      h6 {
        font-size: 1.3rem !important;
      }
      P {
        font-size: 0.9rem;
      }
    }
  }
  .cdis--card-with-description {
    .cdis--description-container {
      h6 {
        font-size: 1.3rem !important;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }
  .cdis--card-with-title .cdis--title-container h6 {
    font-size: 1.5rem;
  }
}
@media (max-width: 900px) {
  .cdis--card-with-description,
  .cdis--news-card {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .cdis--card-with-title {
    width: 100%;
  }
}
@media (max-width: 410px) {
  .cdis--card-container {
    .cdis--description-container {
      h6 {
        font-size: 1.1rem;
      }
      .cdis--box-container {
        p {
          font-size: 1rem;
        }
        span {
          font-size: 1.3rem;
        }
        label {
          font-size: 1.3rem;
        }
      }
    }
  }
  .cdis--card-with-description {
    .cdis--description-container {
      h6 {
        font-size: 1.4rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }
  .cdis--card-with-title {
    .cdis--title-container {
      h6 {
        font-size: 1.4rem;
      }
    }
  }
  .cdis--news-card {
    .cdis--description-container {
      h6 {
        font-size: 1.4rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }
}
