@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

.cdis--programms-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  flex-wrap: wrap;
  padding:0 2%;
  .cdis--cader-content {
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width:950px){
.cdis--programms-section {
  .cdis--cader-content {
    .cdis--text-bold-medium{
        font-size: 1.4rem !important;

    }
  }
}
}
@media (max-width:800px){
.cdis--programms-section {
  .cdis--cader-content {
    width: 91%;
  }
}
}

@media (max-width:420px){
.cdis--programms-section {
  .cdis--cader-content {
    .cdis--text-bold-medium{
        font-size: 1.2rem !important;
        text-align: center;

    }
  }
}
}