@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";
.title-hidden{
  display: none;
}
.cdis--section-title-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 2vw;

  .cdis--title-section-divider {
    border: 1px solid $primaryColor;
    opacity: 0.3;
    width: 38%;
    max-width: calc(50% - 2vw);
    min-width: 0;
    margin: 0;
  }
  .cdis--title {
    white-space: nowrap;
    color: $primaryColor;
    font-family: $fontFamilyBeiruti;
    font-weight: $fontWeightBold;
    font-size: $fontSizeLarge;
    margin-bottom: 1%;
  }
}
