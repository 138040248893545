@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

.cdis--localisation-section-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8vw;
  padding: 0 2% 4%;

  .cdis-licalisation-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
  }

  .cdis-localisation-description {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1vw;

    .cdis-localisation-title {
      width: 100%;
      text-align: center;
      font-family: $fontFamilyBeiruti;
      font-weight: $fontWeightBold;
      color: $primaryColor;
      font-size: $fontSizeXLarge;
    }
  }
}

@media (max-width: 1138px) {
  .cdis--localisation-section-container {
    .cdis-localisation-description {
      .cdis-localisation-title {
        font-size: 2.5rem;
      }
      img {
        width: 18%;
      }
    }
  }
}

@media (max-width: 900px) {
  .cdis--localisation-section-container {
    .cdis-localisation-description {
      .cdis-localisation-title {
        font-size: 2rem;
      }
      img {
        width: 15%;
      }
    }
  }
}
@media (max-width: 670px) {
  .cdis--localisation-section-container {
    padding: 0 5%;
    flex-direction: column;
    .cdis-licalisation-image{
      width:100%
    }
    .cdis-localisation-description {
      width: 100%;
    }
  }
}
