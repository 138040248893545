@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

.cdis--footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 5vw;
  background-color: $primaryColor;
  padding: 2%;
  .cdis--footer-titles-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;

    .cdis--footer-title {
      line-height: 1.5;
      text-align: center;
      font-family: $fontFamilyBeiruti;
      font-weight: $fontWeightNormal;
      color: $whiteColor;
      font-size: $fontSizeSmall;
    }
  }

  .cdis--footer-section-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    .cdis--image-container {
      width: 11rem;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    .cdis--footer-divider {
      width: 20%;
      min-width: 20%;
      background-color: $whiteColor;
      align-self: flex-end;
      margin: 0 0 1% 0;
    }
  }
  .cdis--footer-details {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 1vh;
    flex-direction: column;
  }
  .cdis--details-item {
    line-height: 1.25;
    font-family: $fontFamilyBeiruti;
    font-weight: $fontWeightNormal;
    color: $whiteColor;
    direction: rtl;
    font-size: 1.5rem;
  }
}

/*****************Medias Queries********************/

@media (max-width: 1138px) {
  .cdis--footer-container {
    .cdis--details-item {
      font-size: 1.3rem;
    }
    .cdis--footer-divider {
      width: 32%;
      min-width: 32%;
    }
  }
}
@media (max-width: 900px) {
  .cdis--footer-container {
    .cdis--footer-titles-container {
      .cdis--footer-title {
        font-size: 1.3rem;
      }
    }
    .cdis--details-item {
      font-size: 1.1rem;
    }
  }
}

@media (max-width: 650px) {
  .cdis--footer-container {
    .cdis--footer-divider {
      display: none;
    }
    .cdis--details-item {
      font-size: 1rem;
    }
    .cdis--footer-section-box {
      justify-content: space-between;
      padding: 0 4%;
      .cdis--image-container {
        width: 8rem;
      }
    }
  }
}


