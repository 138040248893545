@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";
.cdis--loader-container {
  display: flex;
  justify-content: center;
  background-color: $whiteColor;
  z-index: 9;
}
//
.cdis--perspectives-container {
  width: 100%;
  // height: 85vh;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  flex-direction: column;
  gap: 2vh;
.cdis--perspectives-box-container{
min-height: 24rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
  .cdis--perspectives-header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    direction: rtl;
    .cdis--perspectives-title {
      font-family: $fontFamilyBeiruti;
      font-size: 2rem;
      font-weight: $fontWeightNormal;
      color: $primaryColor;
      width: 40%;
      text-align: start;
    }
    .cdis--perspectives-box {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1vw;
      width: 60%;
    }
  }
  .cdis--perspectives-table {
    // min-height: 25rem;
    width: 100%;
    direction: rtl;
  }
}
//table css
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-pagination.ant-pagination {
  justify-content: center;
  margin: 46px 0 0;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  background: $colorInputs;
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeXSmall;
  font-weight: $fontWeightNormal;
  color: $primaryColor;
  // border-radius: $borderRadius-19;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-thead
  > tr {
  border-radius: $borderRadius-19;
}

:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-tbody
  .ant-table-row
  > .ant-table-cell-row-hover {
  background: rgba(242, 118, 46, 0.2);
  color: $primaryColor;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-tbody
  .ant-table-row
  > .ant-table-cell-row-hover:first-child {
  border-radius: 0 $borderRadius-19 $borderRadius-19 0;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-tbody
  .ant-table-row
  > .ant-table-cell-row-hover:last-child {
  border-radius: $borderRadius-19 0 0 $borderRadius-19;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper .ant-table-cell,
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td,
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper tfoot > tr > th,
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper tfoot > tr > td {
  color: $primaryColor;
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeXSmall;
  font-weight: $fontWeightNormal;
  color: $primaryColor;
  text-align: center;
  padding: 10px 10px;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-radius: 0 1.2rem 1.2rem 0;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-radius: 1.2rem 0 0 1.2rem;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  transition: background 0.2s, border-color 0.2s;
  border-right: 1px solid $colorInputs;
  border-bottom: none;
  text-align: center;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th:first-child,
:where(.css-dev-only-do-not-override-98ntnt).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td:first-child {
  border-right: none;
}

.cdis--btn-perspective-container {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}

.cdis--btn-perspective {
  padding: 0.1rem 1.55rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.cdis--btn-perspective-2 {
  padding: 0.1rem 2.2rem;
  background-color: $secondaryColor;
}
//Oppportunity Details Component

.cdis--opportunity-details-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 3vh;

  .cdis--opportunity-details-table {
    width: 100%;
    direction: rtl;
    max-height: 15.75rem;
    // min-height: 20rem;
    overflow-y: auto;
    height: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .cdis--opportunity-details-header {
    width: 100%;
    display: flex;
    align-items: center;
    direction: rtl;
    .cdis--opportunities-title {
      font-family: $fontFamilyBeiruti;
      font-size: 2rem;
      font-weight: $fontWeightNormal;
      color: $primaryColor;
      text-align: start;
    }
    .cdis--opportunities-subtitle {
      font-family: $fontFamilyBeiruti;
      font-size: 1.5rem;
      font-weight: $fontWeightNormal;
      color: $primaryColor;
      width: 70%;
      text-align: center;
    }
  }
}
