@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";
:where(.css-dev-only-do-not-override-98ntnt).ant-divider-horizontal {
  margin: 0 !important;
}
//Opportunities Dashboard
.cdis--dashboard-opportunities-container {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4vh;

  .cdis--dashboard-opportunities-header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    direction: rtl;
    .cdis--opportunities-title {
      font-family: $fontFamilyBeiruti;
      font-size: 2rem;
      font-weight: $fontWeightNormal;
      color: $primaryColor;
      width: 40%;
      text-align: start;
    }
  }
  .cdis--opportunities-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
  }
  .cdis--opportunities-cards-container {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #f3f3f3;
    border-radius: 26px;
    flex-direction: column;
    max-height: calc(3 * 11.25rem);
    // min-height: 20rem;
    overflow-y: auto;
    height: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }

    .cdis--divider-opportunities {
      border: 1px solid $primaryColor;
      opacity: 40%;
      margin: 0;
    }
  }
}
//Opportunities Section
.cdis--opportunities-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1vw;
  align-items: center;
  width: 100%;
  .cdis--opportunities-section-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1vw;
    width: 100%;
  }
  .cdis--buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1vw;
    .cdis--steps-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      .cdis--steps-selected {
        cursor: pointer;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background-color: $secondaryColor;
      }
      .cdis--steps-unselected {
        cursor: pointer;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background-color: $secondaryColorDark;
      }
    }
    button {
      cursor: pointer;
      background-color: transparent;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 1px solid $secondaryColor;
    }
  }
}
.cdis--no-message-container {
  width: 25rem;
  margin-top: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  color: #333;
  font-family: $fontFamilyBeiruti;
    font-size: 1.55rem;
    font-weight: $fontWeightNormal;
  text-align: center;
  border: 1px solid #ddd;
}
/************************Medias Queries*******************/
@media (max-width: 900px) {
  .cdis--opportunities-section {
    gap: 3vw;
    .cdis--opportunities-section-content {
      gap: 4vw;
    }
  }
}
