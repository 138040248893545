@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

.cdis--form-inscrit-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 3rem;
  padding-top: 3rem;
  .cdis--error {
    justify-content: flex-end;
  }
  :where(.css-dev-only-do-not-override-98ntnt).ant-upload-wrapper
    .ant-upload-select {
    width: 100% !important;
    display: inline-block;
  }
  .cdis--upload-file {
    :where(.css-dev-only-do-not-override-98ntnt).ant-upload-wrapper
      .ant-upload {
      outline: 0;
      width: 100% !important;
    }
  }
  .cdis--upload {
    width: 100% !important;
    margin-top: 3vh;
    padding: 5% !important;
    border-radius: 1.2rem;
    border: 1px solid $colorInputs !important;
    outline: none;
    background: $colorInputs !important;
    span {
      color: $primaryColor;
      font-size: 1.85rem;
      font-weight: $fontWeightNormal;
      font-family: $fontFamilyBeiruti;
    }
  }
}

.cdis--form-img-header {
  display: flex;
  justify-content: center;
  gap: 1vw;
  img {
    cursor: pointer;
  }
}

.cdis--step-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.cdis--form-inscrit-container-home {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 5rem;
  padding-top: 3rem;
}

.cdis--form-inscrit-home-page-card {
  display: flex !important;
  justify-content: center !important;
}

.cdis--form-inscrit-home-page-card-1 {
  width: 100% !important;
  max-width: 600px !important;
  text-align: center !important;
  background-color: $primaryColor !important;
  cursor: "pointer" !important;
}

.cdis--form-inscrit-home-page-card-2 {
  width: 100% !important;
  max-width: 600px !important;
  text-align: center !important;
  background-color: $secondaryColor !important;
  cursor: "pointer" !important;
}

.cdis--text-card {
  color: white;
}

.cdis--form-inscrit-body {
  width: 80%;
  @media (max-width: 641px) {
    width: 90%;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  gap: 3rem;
  .cdis--form-inscrit-step {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
  }
  .cdis--form-inscrit-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3vh;
  }
}
.cdis--mt-3 {
  height: 5rem;
  margin-top: 3vh;
}

.cdis--step-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 3rem;
  column-gap: 8.2rem;
}

.cdis--step-form-large {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  direction: rtl;
  row-gap: 1rem;
  column-gap: 1rem;
}
