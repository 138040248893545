@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

//Opportunity Modal Edit

.modal-add-person {
  width: 79% !important;
  margin-left: 2rem;
  height: 79vh !important;
  .cdis--modal-edit-opportunity-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3vh;
    height: 77vh;
    flex-direction: column;
    .cdis--form-inscrit-body {
      width: 90%;
    }
    h1 {
      color: $primaryColor;
      font-family: $fontFamilyBeiruti;
      font-size: $fontSizeLarge;
      font-weight: $fontWeightNormal;
    }
    h4 {
      color: $primaryColor;
      font-family: $fontFamilyBeiruti;
      font-size: $fontSizeSmall;
      font-weight: $fontWeightNormal;
    }
    .cdis--divider {
      border: 1px solid $secondaryColor;
      width: 69%;
      max-width: calc(69% - 2vw);
      min-width: 0;
      margin: 0;
    }
    .cdis--modal-edit-opportunity-inputs {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 1vw;
      flex-direction: row-reverse;

      .cdis--modal-input1 {
        width: 60%;
        font-size: 1.5rem;
        &::placeholder {
          font-size: 1.5rem;
        }
      }
      .cdis--modal-input2 {
        width: 40%;
        font-size: 1.5rem;
        &::placeholder {
          font-size: 1.5rem;
        }
      }
    }
    .cdis--modal-edit-opportunity-inputs-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1vw;
      direction: rtl;
      h4 {
        width: 19%;
      }
      .cdis--modal-edit-opportunity-input {
        width: 80%;
        font-size: 1.5rem;
        &::placeholder {
          font-size: 1.5rem;
        }
      }
    }
  }

  :where(.css-dev-only-do-not-override-98ntnt).ant-modal .ant-modal-close {
    border: 1px solid $primaryColor !important;
    border-radius: 50% !important;
    svg {
      color: $primaryColor !important;
    }
  }

  .cdis--modal-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    direction: rtl;
    gap: 1vw;
    margin-top: 3%;
  }
}
