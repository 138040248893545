@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";
.cdis--loader-prespective{
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2vh;
}

.cdis--perspectives-file-container {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 2vh;
}
  .cdis--perspectives-header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    direction: rtl;
    .cdis--perspectives-title {
      font-family: $fontFamilyBeiruti;
      font-size: 2rem;
      font-weight: $fontWeightNormal;
      color: $primaryColor;
      width: 40%;
      text-align: start;
    }
    .cdis--perspectives-box {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1vw;
      width: 60%;
    }
  }
  .cdis--perspectives-file-table {
    min-height: 25rem;
    width: 100%;
    direction: rtl;
  }

