@import "../variables/color.scss";
.cdis--loader-container {
  display: flex;
  justify-content: center;
  background-color: $whiteColor;
  z-index: 9;
  opacity:0.8;
  width: 100%;
  height: 100%;
  position: absolute;
}
.cdis--dashboard-home-chart-container {
  width: 100%;
  height: 22rem;
}

.cdis--dashboard-home-icon {
  font-size: 2rem;
  color: $secondaryColorDark;
}

.cdis--cadre-dashboard-doughnut {
  width: 100%;
  height: 100%;
  padding: 4%;
}

.cdis--dashboard-home {
  padding: 3%;
}
