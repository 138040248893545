@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

.cdis--news-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2vw;
  .cdis--news-box-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 2vw;
  }
  .cdis--news-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 50%;
    gap: 2vw;
  }
}
.cdis--new-details {
  padding: 2%;
  direction: rtl;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  direction: rtl;
  gap: 4vw;

  .cdis--image-container {
    width: 100%;
    align-self: flex-end;
  }

  .cdis--details-container {
    width: 100%;
    h2 {
      font-family: $fontFamilyBeiruti;
      color: $primaryColor;
      font-weight: $fontWeightBold;
      font-size: $fontSizeLarge;
      text-align: center;
      width: 100%;
    }
  }
  .cdis--details-item-container {
    margin: 0 auto;
    margin-top:5vh;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 1vw;
    direction: rtl;
    overflow: hidden;

    img {
      height: 40dvh;
      width: 100%;
    }
  }

  p,
  h2 {
    text-align: right;
    font-family: $fontFamilyBeiruti;
    color: $primaryColor;
  }
  h2{
    font-weight: $fontWeightBold;
    font-size: $fontSizeSmall;
  }
  p {
    width: 100%;
    font-size: $fontSizeXSmall;
    line-height: 1.75;

    font-weight: $fontWeightNormal;
  }
}

.cdis--details-item{
  width: 100%;
  align-items: center;
}

@media (max-width: 900px) {
  .cdis--news-section {
    .cdis--news-box-content {
      flex-wrap: wrap;
    }
    .cdis--news-card-container {
      width: 100%;
    }
  }
}
@media (max-width: 480px) {
  .cdis--news-card-container {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  :where(.css-dev-only-do-not-override-98ntnt).ant-row {
    gap: 2vw;
  }
}
