@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

.cdis--hero-section-container {
  width: 100%;
  border-radius: $borderRadius-57;
  overflow: hidden;
  min-height: 74vh;
  .cdis--hero-section-image {
    width: 100%;
    object-fit: cover;
    border-radius: $borderRadius-57;
  }
}

@media (max-width: 900px) {
  .cdis--hero-section-container {
    min-height: 0;
  }
}