@import "../variables/color.scss";
.cdis--loader-container {
  display: flex;
  justify-content: center;
  background-color: $whiteColor;
  z-index: 9;
}
.cdis--base-component-container {
  flex-direction: column;
  padding-top: 2%;
  margin-top: 6rem;
  gap: 5vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .cdis--section-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5vh;
  }
  .cdis--divider-section {
    width: 41.5% !important;
    min-width: 0 !important;
  }
  .cdis--divider-section-news {
    width: 40% !important;
    min-width: 0 !important;
  }
  .cdis--title-section-divider {
    border: 1px solid $primaryColor;
    opacity: 0.3;
    width: 94%;
    max-width: 94%;
    margin: 0;
  }
}
