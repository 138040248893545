@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

.cdis--partners-section-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2vw;
  padding: 0 2%;

  .cdis--image-container {
    width: 13rem;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}
