@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";
//Caroussel Container
.cdis--caroussel-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5vw;
  padding: 0 2.5%;

  .cdis--image-container {
    width: 28rem;
    height: 35rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $colorInputs;
    border-radius: 43px;
    img {
      object-fit: contain;
      width: 100%;
    }
  }
}
.cdis--caroussel-content-container {
  height: 25rem;
  display: flex;
  width: 60%;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  gap: 2vw;
  .cdis--description-container {
    height: 80%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    gap: 1vw;
  }
  .cdis--text-align {
    direction: rtl;
    width: 75%;
  }
  .cdis--buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 1vw;
    .cdis--steps-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      .cdis--steps-selected {
        cursor: pointer;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background-color: $secondaryColor;
      }
      .cdis--steps-unselected {
        cursor: pointer;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        background-color: $secondaryColorDark;
      }
    }
    button {
      cursor: pointer;
      background-color: transparent;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 1px solid $secondaryColor;
    }
  }
}
/*-----------------------------------Media Queries ---------------------*/
@media (max-width: 1070px) {
  .cdis--caroussel-content-container {
    .cdis--text-align {
      width: 100%;
      font-size: 1.7rem;
    }
  }
}

@media (max-width: 900px) {
  .cdis--caroussel-container {
    flex-direction: column;
    padding: 2%;
    .cdis--image-container {
      width: 90%;
      overflow: hidden;
    }
  }
  .cdis--caroussel-content-container {
    width: 81%;
    
    .cdis--buttons-container {
      justify-content: center;
    }
  }
}
@media (max-width: 650px) {
  .cdis--caroussel-content-container {
    width: 96%;
    .cdis--text-align {
      font-size: 1.5rem;
    }
  }
}
@media (max-width: 485px) {
  .cdis--caroussel-content-container {
    .cdis--text-align {
      font-size: 1.3rem;
    }
  }
}
