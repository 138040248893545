@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

//Opportunity Modal Edit
.ant-modal {
  width: 53.5rem !important;
}
.error-input1 {
  width: 55%;
}
.error-select {
  width: 40%;
}
.cids-input-error {
  height: 6rem;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  direction: rtl;
  span {
    color: red;
    font-family: $fontFamilyBeiruti;
    font-size: 1rem;
    font-weight: $fontWeightNormal;
  }
}
.cdis--new-textarea {
  width: 80%;
  height: 10rem;
  border: none;
  outline: none;
  background-color: $colorInputs;
  padding: 1.5%;
  border-radius: 1.2rem;
  font-size: 1.5rem;
  font-weight: $fontWeightNormal;
  font-family: $fontFamilyBeiruti;
  direction: rtl;
  color: $primaryColor;
  &::placeholder {
    color: $primaryColor;
    font-size: 1.5rem;
    font-weight: $fontWeightNormal;
    font-family: $fontFamilyBeiruti;
    direction: rtl;
    opacity: 0.4;
  }
}
textarea {
  border: none;
  box-shadow: none;
  outline: none;
  overflow: auto;
  resize: none;
}
:where(.css-dev-only-do-not-override-98ntnt).ant-modal .ant-modal-close {
  border: 1px solid $primaryColor !important;
  border-radius: 50% !important;
  svg {
    color: $primaryColor !important;
  }
}
.cdis--modal-delete {
  gap: 3vh !important;
}
.cdis--modal-align {
  justify-content: flex-start !important;
  gap: 0 !important;
}
.cdis--modal-edit-opportunity-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3vh;
  flex-direction: column;
  .cdis--edit-news-image {
    #cdis--button-upload {
      width: 80% !important;
    }
  }
  h1 {
    color: $primaryColor;
    font-family: $fontFamilyBeiruti;
    font-size: $fontSizeLarge;
    font-weight: $fontWeightNormal;
  }
  h4,
  h5 {
    color: $primaryColor;
    font-family: $fontFamilyBeiruti;
    font-size: $fontSizeSmall;
    font-weight: $fontWeightNormal;
  }
  .cdis--divider {
    border: 1px solid $secondaryColor;
    width: 69%;
    max-width: calc(69% - 2vw);
    min-width: 0;
    margin: 0;
  }
  .cdis--modal-edit-opportunity-inputs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1vw;
    flex-direction: row-reverse;

    .cdis--modal-input1 {
      font-size: 1.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
      &::placeholder {
        font-size: 1.5rem;
      }
    }
    .cdis--modal-select {
      font-size: 1.5rem;
      &::placeholder {
        font-size: 1.5rem;
      }
    }
    .cdis--modal-input2 {
      font-size: 1.5rem;

      &::placeholder {
        font-size: 1.5rem;
      }
    }
  }

  .cdis--modal-edit-opportunity-inputs-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    direction: rtl;
    .cdis--modal-h4 {
      width: 24%;
      height: 6rem;
    }
    .cdis--new-label {
      height: 6rem;
    }
    :where(.css-dev-only-do-not-override-98ntnt).ant-upload-wrapper {
      width: 100% !important;
    }
    :where(.css-dev-only-do-not-override-98ntnt).ant-upload-wrapper
      .ant-upload-select {
      width: 100% !important;
      display: inline-block;
    }
    .cdis--upload-file {
      :where(.css-dev-only-do-not-override-98ntnt).ant-upload-wrapper
        .ant-upload {
        outline: 0;
        width: 100% !important;
      }
    }
    .cdis--upload {
      width: 100% !important;

      padding: 3.75% !important;
      border-radius: 1.2rem;
      border: 1px solid $colorInputs !important;
      outline: none;
      background: $colorInputs !important;
      span {
        color: $primaryColor;
        font-size: 1.5rem;
        font-weight: $fontWeightNormal;
        font-family: $fontFamilyBeiruti;
      }
    }
    h4 {
      width: 19%;
    }
    .cdis--modal-edit-opportunity-input {
      width: 80%;
      font-size: 1.5rem;
      &::placeholder {
        font-size: 1.5rem;
      }
    }
  }
}
.cdis--modal-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  direction: rtl;
  gap: 1vw;
  margin-top: 3%;
  button {
    padding: 0.5rem 2.5rem;
  }
}
.cdis-details-modal{
  display: flex;
  flex-direction: column;
  gap: 1vh;
  width: 100%;
  direction: rtl;
  h3{
    
    color: $primaryColor;
    font-family: $fontFamilyBeiruti;
    font-size: 1.8rem;
    font-weight: $fontWeightXMedium;
  }
  
  .cdis--details-modal-content{
    display: flex;
    align-items: flex-start;
    flex-direction: center;
  gap: 3vw;
  h4{
    width: fit-content;
  }
  }
}