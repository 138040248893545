@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

.cdis--pagination-table .ant-pagination-item {
  border: $borderWidth-1 solid $secondaryColor !important;
  border-radius: $borderRadius-50;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}

.cdis--pagination-table .anticon {
  color: $primaryColor !important;
}

.cdis--pagination-table .ant-pagination-item a {
  color: $secondaryColor !important;
}

.cdis--pagination-table .ant-pagination-item-active a {
  color: $whiteColor !important;
}

.cdis--pagination-table .ant-pagination-item-active {
  background-color: $secondaryColor;
  border-color: $secondaryColor;
}

.cdis--pagination-table .ant-pagination-item-link {
  border: $borderWidth-1 solid $primaryColor !important;
  border-radius: $borderRadius-50;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}

.cdis--pagination-table .ant-pagination-prev,
.cdis--pagination-table .ant-pagination-next {
  margin-right: 0.5rem;
}

.cdis--pagination-table .ant-pagination-prev .ant-pagination-item-link,
.cdis--pagination-table .ant-pagination-next .ant-pagination-item-link {
  border-radius: $borderRadius-50;
}
