@import "../variables/color.scss";
@import "../variables/font.scss";
.cdis--loader-container {
  display: flex;
  justify-content: center;
  background-color: $whiteColor;
  z-index: 9;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  position: absolute;
}

.cdis--login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 5rem;
  padding-top: 2rem;

  @media (max-width: 768px) {
    gap: 2rem;
    width: 100vw;
    height: max-content;
    padding-top: 1rem;
  }
}

.cdis--login-header {
  display: flex;
  align-items: center;
  gap: 1vw;
  img {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
}

.cdis--login-header-text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 768px) {
    gap: 3rem;
    text-align: center;
  }
}

.cdis--login-img-container {
  img {
    width: 100%;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.cdis--login-form-container {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (max-width: 768px) {
    justify-content: center;
  }
}

.cdis--captcha-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.cdis--login-input-password {
  position: relative;
  display: inline-block;
}

.cdis--login-password-visibility-btn {
  position: absolute;
  left: 3rem;
  top: 51%;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
}
.cdis--login-password-visibility {
  font-size: $fontSizeXSmall;
  color: $primaryColor;
}

.cdis--login-password-invisibility {
  font-size: $fontSizeXSmall;
  color: $primaryColor;
}
