@import "../variables/color.scss";
@import "../variables/font.scss";
@import "../variables/border.scss";

.cdis--steps-component {
  direction: rtl; /* Apply RTL direction to the entire component */
  .ant-steps-item-title {
    font-family: $fontFamilyBeiruti;
    font-size: $fontSizeSmall !important;
    font-weight: $fontWeightNormal !important;
    transition: color 0.3s, opacity 0.3s;
  }

  .ant-steps-item-finish .ant-steps-item-title,
  .ant-steps-item-process .ant-steps-item-title {
    color: $primaryColor !important;
    opacity: 1 !important;
  }

  .ant-steps-item-wait .ant-steps-item-title {
    color: $primaryColor !important;
    opacity: 0.4 !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon,
  .ant-steps-item-process .ant-steps-item-icon,
  .ant-steps-item-wait .ant-steps-item-icon {
    display: none;
  }

  .ant-steps-item {
    flex-direction: column-reverse;
  }

  .ant-steps-item-title {
    margin-bottom: 8px;
  }

  .ant-steps-item-finish .ant-steps-item-tail::after,
  .ant-steps-item-process .ant-steps-item-tail::after,
  .ant-steps-item-wait .ant-steps-item-tail::after {
    background-color: $blackColor !important;
  }

  .ant-steps-item-tail::after {
    transition: none !important;
  }

  .step-title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .step-title-number {
    color: $primaryColor;
    font-size: $fontSizeMedium;
    font-weight: $fontWeightNormal;
    margin-left: 0.5rem;
  }

  .cdis--img-step {
    margin-right: 0.5rem;
  }
}
