@import "../variables/color.scss";
@import "../variables/font.scss";

.cdis--text-primary {
  color: $primaryColor;
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeLarge;
  font-weight: $fontWeightNormal;
}

.cdis--text-secondary {
  color: $primaryColor;
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeMedium;
  font-weight: $fontWeightNormal;
}

.cdis--text-bold-medium {
  color: $primaryColor;
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeSmall;
  font-weight: $fontWeightBold;
}

.cdis--text-paragraph {
  color: $primaryColor;
  font-family: $fontFamilyBeiruti;
  font-size: $fontSizeXMedium;
  font-weight: $fontWeightLight;
}

@media (max-width: 1070px) {
  .cdis--text-primary {
    font-size: 2.2rem;
    text-align: end;
    width: 100%;
  }
}
@media (max-width: 900px) {
  .cdis--text-primary {
    text-align: center;
  }
  .cdis--text-paragraph {
    font-size: 1.6rem;
  }
}
